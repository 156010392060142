<template>
  <div class="warp">
    <div class="reset-box">
      <slot />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.warp {
  min-height: 100vh;
  padding: 75px 0;
  background-image: url("../../assets/images/main/back.png");
  background-size: 100vw 100vh;
  background-color: $beige;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  .reset-box {
    width: 966px;
    border: 1px #fff solid;
    background: #ffffff;
    box-shadow: 0px 8px 39px -9px rgba(164, 89, 38, 0.15);
    border-radius: 20px;
    margin: 0 auto;
  }
}
</style>
